import React from 'react'

import { COPYRIGHT, ROOT_URL } from 'constants/general.constants'

import { APP_TERMS_PATH, APP_PRIVACY_PATH, APP_IMPRINT_PATH } from 'constants/routes/app.constants'
import { patchLink } from 'utils/link.utils'

import './app-footer.scss'

const FOOTER_LINKS = {
  imprint: patchLink(`${ROOT_URL}${APP_IMPRINT_PATH}`),
  privacy: patchLink(`${ROOT_URL}${APP_PRIVACY_PATH}`),
  terms: patchLink(`${ROOT_URL}${APP_TERMS_PATH}`),
}

const AppFooter = () => (
  <div className='app-footer'>
    <div className='app-footer__links'>
      <a href={patchLink(FOOTER_LINKS.terms)}>{I18n.t('react.app.footer.terms')}</a>
      <a href={patchLink(FOOTER_LINKS.privacy)}>{I18n.t('react.app.footer.privacy')}</a>
      <a href={patchLink(FOOTER_LINKS.imprint)}>{I18n.t('react.app.footer.imprint')}</a>
    </div>

    <div className='app-footer__copyright'>{COPYRIGHT}</div>
  </div>
)

export default AppFooter
